import React from "react";
// import classnames from "classnames";

const PhoneFrame = () => (
  // const { src, alt, className } = props;

  <>
    {/* <Image
        className={classnames("phone-frame", className)}
        alt={alt}
        image={src}
        size={256}
      /> */}
  </>
);
export default PhoneFrame;
